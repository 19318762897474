import "./index.scss"
export default function Booking({setPaymentShow}) {
    const BookingClick = () =>{
        setPaymentShow(true)
    }
    return (
        <>
            <div className="car-washing-booking">
                <form onSubmit={BookingClick}>

                    <div class="form-group">
                        <h2 class="heading"><b>Booking</b> </h2>
                        <div class="controls">
                        <label for="phone">Phone</label>
                            <input type="tel" id="phone" class="floatLabel" name="phone" required />
                           
                        </div>
                        <div class="grid">
                            <div class="col-2-3">
                                <div class="controls">
                                <label for="street">Street</label>
                                    <input type="text" id="street" class="floatLabel" name="street" required />
                                    
                                </div>
                            </div>
                           
                        </div>
                        <div class="grid">
                            <div class="col-2-3">
                                <div class="controls">
                                <label for="city">City</label>
                                    <input type="text" id="city" class="floatLabel" name="city" required/>
                                  
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="controls">
                                <label for="post-code">Post Code</label>
                                    <input type="text" id="post-code" class="floatLabel" name="post-code" required/>
                                   
                                </div>
                            </div>
                        </div>
                  
                        <div class="grid">
                            <div class="col-1-4 col-1-4-sm">
                                <div class="controls">
                                <label for="arrive" class="label-date"><i class="fa fa-calendar"></i>Date</label>
                                    <input type="date" id="arrive" class="floatLabel" name="arrive" required/>
                                   
                                </div>
                            </div>
                            <div class="col-1-4 col-1-4-sm">
                                <div class="controls">
                                <label for="depart" class="label-date"><i class="fa fa-calendar"></i>Time</label>
                                    <input type="time" id="depart" class="floatLabel" name="depart" required/>
                                    
                                </div>
                            </div>
                        </div>
                   
                    </div> 
                    <button type="submit" >Submit</button>
                </form>
            </div>
        </>
    )
}