// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Login } from '../login';
import "./index.scss"
import { Link } from 'react-router-dom';
export const Header = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [scrolltopdata, setscrolltopdata] = React.useState('');

    React.useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 500) {
                setscrolltopdata('');
            } else {
                setscrolltopdata('scrolled');
            }
        });
    }, [])

    return (
        <>
            <div className="car-washing-wale-navbar ">
                <div className={`main-menu ${scrolltopdata}`}>
                    <div className="top-menu">
                        <div class=" mobile-menu-wahingwale ">
                            <div class="img-box dropbtn">
                                <img src="./assets/user.png" alt="some user image" />
                            </div>
                            <div class="dropdown-content">

                                <div class="">
                                    <ul class="list-unstyled">
                                        <li>
                                            <a class="dropdown-item-user" href="/profile">
                                                <span class="mr-1">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                </span>Profile
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a class="dropdown-item-user" href="@@webRoot/pages/student-subscriptions.html"><span class="mr-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></span>Subscription
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item-user" href="#!">
                                                <span class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></span>Settings
                                            </a>
                                        </li> */}
                                        <hr />
                                        <li>
                                            <a class="dropdown-item-user" href="/login" style={{ padding: 0 }}>
                                                <span class="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg></span>Sign Out
                                            </a>
                                        </li>

                                    </ul>
                                </div>



                            </div>
                        </div>
                        <Navbar expand="lg" className="washing-nav">
                            <Container fluid>
                                <Navbar.Brand href="#">  <div className="logo"><a href="/"><img src='/washingwale-logo.png' alt='logo' /></a></div>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="navbarScroll" />
                                <Navbar.Collapse id="navbarScroll" className="justify-content-space-between">
                                    <Nav>
                                        <Nav.Link href="/washing-wale-shopping?Basic Wash"><span>Basic Wash</span></Nav.Link>
                                        <Nav.Link href="/washing-wale-shopping?Deluxe Wash"><span>Deluxe Wash</span></Nav.Link>
                                        <Nav.Link href="/washing-wale-shopping?Interior Cleaning"><span>Interior Cleaning</span></Nav.Link>
                                        <Nav.Link href="/washing-wale-shopping?Detailing"><span>Detailing</span></Nav.Link>


                                    </Nav>
                                    <Nav className='deshbord'>
                                            <div class="dropdown-manu">
                                                <div class="img-box dropbtn">
                                                    <img src="./assets/user.png" alt="some user image" />
                                                </div>
                                                <div class="dropdown-content">
                                                    <div class="">
                                                        <ul class="list-unstyled">
                                                            <li>
                                                                <a class="dropdown-item-user" href="/profile">
                                                                    <span class="mr-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                                    </span>Profile
                                                                </a>
                                                            </li>
                                                            {/* <li>
                                                                <a class="dropdown-item-user" href="/profile"><span class="mr-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg></span>Subscription
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item-user" href="#!">
                                                                    <span class="mr-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></span>Settings
                                                                </a>
                                                            </li> */}
                                                            <hr />
                                                            <li>
                                                                <a class="dropdown-item-user" href="/login" style={{ padding: 0 }}>
                                                                    <span class="mr-2">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg></span>Sign Out
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>



                                                </div>
                                            </div>
                                       
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>

                    </div>
                </div>

            </div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='login-model'
            >

                <Modal.Body>
                    <Login />
                </Modal.Body>

            </Modal>
        </>
    )
}