import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.scss"
export default function Main() {

    return (
        <>
            <div className="main2">
                <Container fluid>
                    <Row>
                        <Col md={8}>
                            <div className="main">
                                <Carousel autoPlay interval="3000" infiniteLoop transitionTime="1000">
                                    <div className="slide title-slider">
                                        {/* <img src="./img/inner-page/car-washing-wale-slider.jpg" alt="slide" /> */}
                                        <h1>Quality Washes, Delivered Fast!</h1>
                                        <p>Shine On the Go – Book Your Wash Today!</p>
                                      <a href="./washing-wale-shopping" >Book Now</a>
                                    </div>
                                    {/* <div className="slide">
                                        <img src="./assets/images/washingwala_car_washing.png" alt="slide" />
                                    </div> */}
                                </Carousel>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div class="car-filter-area">
                                <h3 style={{ color: "#fff",padding:"10px 0" }}>Search Washing Wale</h3>
                                <div class="tab-content" id="myTabContent1">
                                    <div class="tab-pane fade active show" id="new-car" role="tabpanel" aria-labelledby="new-car-tab">
                                        <div class="tab-content tab-content3" id="myTabContent2">
                                            <div class="tab-pane fade active show" id="budget1" role="tabpanel" aria-labelledby="budget1-tab">
                                                <div class="form-inner mb-25">
                                                    <label>Enter location</label>
                                                    <input className="nice-select select" placeholder="Enter location" />
                                                </div>
                                                <div class="form-inner mb-25">
                                                    <label>Select Vehicles*</label>
                                                    <select class="nice-select select" >
                                                        <option value="AL">Sedan</option>
                                                        <option value="A1">Town Car</option>
                                                        <option value="A2">Luxury Car</option>
                                                        <option value="A3">Executive Car</option>
                                                        <option value="A4">Sports Car</option>
                                                    </select></div>
                                                <div class="form-inner last">
                                                    <button class="primary-btn1" type="submit">SEARCH </button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>

        </>
    )
};





