import React from 'react';
import "./index.scss"
const PrivacyPolicy = () => {
  return (
    <div className="privacy">
        <br/> <br/>
      <div className="container">
        <h1>Privacy Policy</h1>

        <section className="policy-section">
          <h2>1. Introduction</h2>
          <p>
            At <strong>Washing Wale</strong>, your privacy is our priority. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our platform. By accessing or using our services, you agree to the practices described in this policy.
          </p>
        </section>

        <section className="policy-section">
          <h2>2. Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address, phone number, and vehicle details provided during registration or booking.
            </li>
            <li>
              <strong>Payment Information:</strong> Billing address and payment details for processing transactions.
            </li>
            <li>
              <strong>Usage Data:</strong> Information about how you use our platform, such as your interactions with features, pages visited, and preferences.
            </li>
            <li>
              <strong>Device Data:</strong> Information about the device used to access our services, including IP address, browser type, and operating system.
            </li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>3. How We Use Your Information</h2>
          <p>We use your information for the following purposes:</p>
          <ul>
            <li>To process bookings and provide the requested services.</li>
            <li>To communicate with you about your account, bookings, and support inquiries.</li>
            <li>To improve our platform, services, and user experience.</li>
            <li>To send promotional offers, updates, and newsletters (with your consent).</li>
            <li>To ensure compliance with legal obligations and protect against fraudulent activity.</li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>4. How We Share Your Information</h2>
          <p>We may share your information in the following circumstances:</p>
          <ul>
            <li>
              <strong>Service Providers:</strong> With third-party providers (e.g., car washing centers) to fulfill your bookings.
            </li>
            <li>
              <strong>Business Partners:</strong> With partners offering complementary services, where applicable.
            </li>
            <li>
              <strong>Legal Requirements:</strong> When required to comply with legal obligations, enforce our Terms and Conditions, or protect our rights.
            </li>
          </ul>
        </section>

        <section className="policy-section">
          <h2>5. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, or misuse. However, no system is completely secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section className="policy-section">
          <h2>6. Your Rights</h2>
          <p>As a user, you have the following rights regarding your personal data:</p>
          <ul>
            <li>To access, update, or delete your personal information.</li>
            <li>To opt out of marketing communications at any time.</li>
            <li>To withdraw consent where applicable.</li>
            <li>To file a complaint with a data protection authority.</li>
          </ul>
          <p>
            To exercise your rights, please contact us at <strong>helpwashingwale@gmail.com</strong>.
          </p>
        </section>

        <section className="policy-section">
          <h2>7. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar technologies to enhance your experience, analyze usage, and deliver personalized content. By using our platform, you consent to our use of cookies. You can manage your cookie preferences through your browser settings.
          </p>
        </section>

        <section className="policy-section">
          <h2>8. Third-Party Links</h2>
          <p>
            Our platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review their privacy policies before sharing any personal information.
          </p>
        </section>

        <section className="policy-section">
          <h2>9. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Changes will be posted on this page with the updated effective date. Your continued use of the platform signifies your acceptance of the revised policy.
          </p>
        </section>

        <section className="policy-section">
          <h2>10. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please reach out to us:<br />
            - <strong>Email:</strong>  helpwashingwale@gmail.com<br />
            - <strong>Phone:</strong> +91 7217838352<br />
          </p>
        </section>
      </div>

      <style jsx>{`
        .privacy-policy-page {
          background-color: #f9f9f9;
          padding: 20px;
        }
        .container {
          max-width: 1000px;
          margin: 0 auto;
          background: #fff;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        h1 {
          text-align: center;
          margin-bottom: 20px;
        }
        .effective-date {
          text-align: center;
          font-style: italic;
          margin-bottom: 20px;
        }
        .policy-section {
          margin-bottom: 30px;
        }
        h2 {
          margin-bottom: 10px;
          color: #333;
        }
        p, ul {
          line-height: 1.6;
          color: #555;
        }
        ul {
          list-style-type: disc;
          margin-left: 20px;
        }
      `}</style>
    </div>
  );
};

export default PrivacyPolicy;
