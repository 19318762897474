
import { Routes, Route, BrowserRouter } from 'react-router-dom'

// import Login from "../Components/login"
import { Home } from "../page/home"
import Productdetails from '../page/productdetails'
import Shopping from '../page/shopping'
import React from 'react';
import Seller from '../page/seller';
import Profile from '../page/profile';
import { Register } from '../page/register';
import { Login } from '../page/login';
import ContactUs from '../page/contactUs';
import AboutUs from '../page/aboutUs';
import CancellationPolicy from '../page/policy/CancellationPolicy';
import TermsAndConditions from '../page/policy/TermsAndConditions';
import PrivacyPolicy from '../page/policy/PrivacyPolicy';
import BookingPolicy from '../page/policy/BookingPolicy';
import UpiPaymentPage from '../page/payment';
import BlogPage from '../utils/BlogPage';

export const Router = () => {
    window.scrollTo(0, 0);
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path="/" element={<Login />} /> */}
                <Route path="/" element={<Home />} />
                <Route path="/washing-wale-details" element={<Productdetails/>} />
                <Route path="/washing-wale-shopping" element={<Shopping/>} />
                <Route path="/washing-wale-seller" element={<Seller/>} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/register" element={<Register/>} />/
                <Route path="/login" element={<Login/>} />
                <Route path="/contact-us" element={<ContactUs/>} />
                <Route path="/about" element={<AboutUs/>} />
                <Route path="/cancellation-policy" element={<CancellationPolicy/>} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/booking-policy" element={<BookingPolicy/>} />
                <Route path="/blog-page" element={<BlogPage/>} />
                {/* <Route path="/payment" element={<UpiPaymentPage/>} /> */}
            </Routes>
          
        </BrowserRouter>

    )
}