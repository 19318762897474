import React from "react";

const BlogPage = () => {
  const blogs = [
    {
      id: 1,
      title: "The Importance of Regular Car Washing ",
      date: "February 3, 2025",
      content:
        "Regular car washing is essential to maintain the appearance and longevity of your vehicle. A professional car washing service removes dirt, grime, and harmful contaminants, keeping your car looking brand new. With eco-friendly car washing techniques, you can protect your car and the environment at the same time.",
    },
    {
      id: 2,
      title: "Eco-Friendly Car Washing Methods",
      date: "February 1, 2025",
      content:
        "Eco-friendly car washing is becoming a popular choice for vehicle owners. Techniques such as steam car washing, waterless car washing, and biodegradable cleaning products ensure effective cleaning without harming the planet. Choosing an eco-friendly car washing service helps conserve water and reduces pollution.",
    },
    {
      id: 3,
      title: "Benefits of Steam Car Washing",
      date: "January 29, 2025",
      content:
        "Steam car washing is a revolutionary method that uses high-temperature steam to clean vehicles inside and out. This technique not only provides a deep clean but also eliminates bacteria and odors. Steam car washing is a safe and efficient way to maintain your vehicle without excessive water usage.",
    },
    {
      id: 4,
      title: "Car Washing at Your Doorstep – Hassle-Free Service ",
      date: "January 25, 2025",
      content:
        "With mobile car washing services, you can enjoy premium car washing at your doorstep. This convenient service saves time and effort while ensuring a thorough clean. Booking a doorstep car washing service means you get professional cleaning without leaving your home.",
    },
  ];

  return (
    <div className="max-w-7xl mx-auto p-6 bg-gray-50 rounded-lg shadow-xl">
      <h1 className="text-4xl font-bold text-blue-700 text-center mb-10">
        Car Washing Blog 
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all hover:scale-105 hover:shadow-2xl"
          >

            <div className="p-6">
              <h2 className="text-2xl font-semibold text-blue-600 hover:text-blue-800 cursor-pointer">
                {blog.title}
              </h2>
              <p className="text-gray-500 text-sm mt-2"> {blog.date}</p>
              <p className="text-gray-700 text-base mt-4">{blog.content}</p>
            
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
