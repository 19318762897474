

import React from 'react';
import Main from '../../components/Main';
import { Listing } from '../listing';
import CarList from '../carlist';
import Discount from '../discount';
// import HowItWorks from '../howItWorks';
// import About from '../about';
import "./index.scss"
import Bannerpage from '../../components/banner';

export const MainPage = () => {
  return (
    <>
      <Main />
      <CarList />
     
      <div>
        <div class="washing-section-title1">
          <span>
            <h2>Most Searched Center</h2></span>
          <sapn><a>View All</a></sapn>
        </div>
        <Listing />
      </div>

      <Bannerpage/>
      <div>
        <div class="washing-section-title1">
          <span>
            <h2>Most Rating Center</h2></span>
          <sapn><a>View All</a></sapn>
        </div>
        <Listing />
      </div>
    </>
  )
}
