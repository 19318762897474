import React from 'react';
import "./index.scss"
const TermsAndConditions = () => {
  return (
    <div className="privacy">
        <br/><br/>
      <div className="container">
        <h1>Terms and Conditions</h1>

        <section className="terms-section">
          <h2>1. Introduction</h2>
          <p>
            Welcome to <strong>Washing Wale</strong>! By accessing or using our platform, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our services.
          </p>
        </section>

        <section className="terms-section">
          <h2>2. Services Provided</h2>
          <p>
            Washing Wale acts as a listing and booking platform connecting users with car washing centers. We do not provide car washing services directly. All services are rendered by independent third-party providers.
          </p>
        </section>

        <section className="terms-section">
          <h2>3. User Accounts</h2>
          <ul>
            <li>Users must provide accurate and complete information when creating an account.</li>
            <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
            <li>Washing Wale reserves the right to suspend or terminate accounts for misuse or violation of these terms.</li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>4. Booking and Payments</h2>
          <ul>
            <li>All bookings are subject to availability and confirmation by the car washing center.</li>
            <li>Payments must be made through the methods available on the platform.</li>
            <li>Refunds, if applicable, will be processed according to our <a href="/cancellation-policy">Cancellation & No Refund Policy</a>.</li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>5. User Responsibilities</h2>
          <ul>
            <li>Provide accurate vehicle and contact details when booking a service.</li>
            <li>Ensure that the vehicle is accessible for the scheduled service.</li>
            <li>Refrain from using the platform for illegal or unauthorized purposes.</li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>6. Third-Party Services</h2>
          <p>
            Washing Wale is not liable for the actions or omissions of third-party service providers listed on the platform. Any disputes regarding services rendered should be resolved directly with the provider.
          </p>
        </section>

        <section className="terms-section">
          <h2>7. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, Washing Wale and its affiliates are not liable for any direct, indirect, incidental, or consequential damages arising from the use of our platform or services.
          </p>
        </section>

        <section className="terms-section">
          <h2>8. Intellectual Property</h2>
          <p>
            All content on the platform, including text, images, logos, and software, is the property of Washing Wale or its licensors. Unauthorized use of this content is strictly prohibited.
          </p>
        </section>

        <section className="terms-section">
          <h2>9. Amendments</h2>
          <p>
            Washing Wale reserves the right to modify these Terms and Conditions at any time. Users will be notified of significant changes via email or platform notifications. Continued use of the platform constitutes acceptance of the revised terms.
          </p>
        </section>

        <section className="terms-section">
          <h2>10. Governing Law</h2>
          <p>
            These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes will be subject to the exclusive jurisdiction of the courts in [Your Location].
          </p>
        </section>

        <section className="terms-section">
          <h2>11. Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, please contact us:<br />
            - <strong>Email:</strong>  helpwashingwale@gmail.com<br />
            - <strong>Phone:</strong> +91 7217838352<br />
          </p>
        </section>
      </div>

      <style jsx>{`
        .terms-page {
          background-color: #f9f9f9;
          padding: 20px;
        }
        .container {
          max-width: 1000px;
          margin: 0 auto;
          background: #fff;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        h1 {
          text-align: center;
          margin-bottom: 20px;
        }
        .effective-date {
          text-align: center;
          font-style: italic;
          margin-bottom: 20px;
        }
        .terms-section {
          margin-bottom: 30px;
        }
        h2 {
          margin-bottom: 10px;
          color: #333;
        }
        p, ul {
          line-height: 1.6;
          color: #555;
        }
        ul {
          list-style-type: disc;
          margin-left: 20px;
        }
        a {
          color: #007bff;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
};

export default TermsAndConditions;
