import React, { useState } from "react";
import "./index.scss"
export default function RegisterSeller() {
    const [state, setstate] = useState({
        CenterName: "",
        GSTNo: "",
        EmailId: "",
        MobileNo: "",
        Locality: "",
        address: "",
        Zip: "",
        Password: ""

    });
    const [gstError, setPhotos] = useState([]);
    const handlGST = (e) => {
        const APIKEY = "3a5155d01b4ebccd14d95af84560ccda"
        console.log(e.target.value)
        if (e.target.value !== "" || e.target.value == null || e.target.value == undefined) {
            fetch(`http://sheet.gstincheck.co.in/check/${APIKEY}/${e.target.value}`)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    console.log(data);
                    setPhotos(data);
                });
        }
        else {
            setPhotos([]);
        }

    }
    return (
        <div className="RegisterSeller">
            <div class="car-washing-ragister">
                <form>
                    <div class="row jumbotron box8">
                        <div class="col-sm-6 form-group">
                            <input type="text" class="form-control" name="fname" onChange={(e) => setstate({ ...state, CenterName: e.target.value })} id="name-f" placeholder="Enter Center Name*" required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="tel" name="phone" class="form-control" id="tel" onChange={(e) => setstate({ ...state, MobileNo: e.target.value })} placeholder="Enter MobileNo Number*" required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="tel" name="phone" class="form-control" id="tel" placeholder="Enter GST Number*"  onChange={handlGST} />
                            {gstError?.flag ? "" : <em>{gstError?.message}</em>}
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="email" class="form-control" name="email" id="email" placeholder="Enter email*" required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="address" class="form-control" name="Locality" id="address-1" placeholder="Locality/House/Street no*" required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="address" class="form-control" name="address" id="address-2" placeholder="Village/City Name*" required />

                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="number" class="form-control" name="Zip" id="zip" placeholder="PIN Code*" required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="Password" name="password" class="form-control" id="pass" placeholder="Enter Password*" required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input type="Password" name="cnf-password" class="form-control" id="pass2" placeholder="Re-enter Password*" required />
                        </div>
                        <div class="col-sm-12 form-group mb-0">
                            <div className="btn-ragister">
                                <button class="btn btn-secondary float-left">Clear</button>
                                <button class="btn btn-primary float-right">Submit</button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    )

}