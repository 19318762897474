import { Container, Form } from 'react-bootstrap';
import './index.scss';
import React from 'react';
export const Login = () => {
    const [password, setshowPassword] = React.useState(false)
    console.log(password)
    const handleLogin = () => {

    }
    return (

        <div className="car-washing-login">
            <br/> <br/>
            <Container fluid>
                <div className="row ">
                    <div class=" col-md-5 mx-auto">
                    <div class="wrapper">
                        <form class="login-box">
                            <div class="login-header">
                                <span>Login</span>
                            </div>
                            <div class="input_box">
                                <input type="email" id="user" class="input-field" required />
                                <label for="user" class="label">Email</label>
                                <i class="fa fa-user icon"></i>
                            </div>
                            <div class="input_box">
                                <input type={
                                    password
                                        ? "text"
                                        : "password"
                                } id="pass" class="input-field" required />
                                <label for="pass" class="label">Password</label>
                                {password ? (<i class="fa fa-unlock icon" id="togglePassword" onClick={() => setshowPassword(false)}></i>) :
                                    <i class="fa fa-lock icon" id="togglePassword" onClick={() => setshowPassword(true)}></i>
                                }
                            </div>
                            <div id="passwordStrength" class="password-strength"></div>

                            <div class="remember-forgot">
                                <div class="remember-me">
                                    <input type="checkbox" id="remember" />
                                    <label for="remember"> Remember me </label>
                                </div>
                                <div class="forgot">
                                    <a href="#">Forgot password ?</a>
                                </div>
                            </div>
                            <div class="input_box">
                                <input type="submit" class="input-submit" value="Login" onclick={handleLogin} />
                            </div>
                            <div class="register">
                                <span>Don't have an account ? <a href="/Register">Register</a></span>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}