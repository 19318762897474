import { Col, Container, Row } from 'react-bootstrap';
import './index.scss';
export const Register = () => {
    return (

        <div className="car-washing-register">
            <br />   <br />
            <Container fluid>
                <Row>
                    <Col md={6}>
                        <div class="page back" style={{backgroundImage:"url(./assets/images/car-washing-signup.jpg)",backgroundPosition:"center",backgroundSize:"cover"}}>
                            <div class="content">
                                <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div class="card">
                            <div class="SignUp-box">
                            <form action="#">
                                <div className='text-center'>
                                <div class="login-header"><span>SignUp</span></div>
                                </div>

                                <div class="user_details">
                                    <div class="input_box">
                                        <label for="name">Full Name</label>
                                        <input type="text" id="name" placeholder="Enter your name" required />
                                    </div>

                                    <div class="input_box">
                                        <label for="email">Email</label>
                                        <input type="email" id="email" placeholder="Enter your email" required />
                                    </div>
                                    <div class="input_box">
                                        <label for="phone">Phone Number</label>
                                        <input type="number" id="phone" placeholder="Enter your number" required />
                                    </div>
                                    <div class="input_box">
                                        <label for="pass">Password</label>
                                        <input type="password" id="pass" placeholder="Enter your password" required />
                                    </div>
                                    <div class="input_box">
                                        <label for="confirmPass">Confirm Password</label>
                                        <input type="password" id="confirmPass" placeholder="Confirm your password" required />
                                    </div>
                                </div>
                                <div class="gender">
                                    <span class="gender_title">Gender</span>
                                    <input type="radio" name="gender" id="radio_1" />
                                    <input type="radio" name="gender" id="radio_2" />
                                    <input type="radio" name="gender" id="radio_3" />

                                    <div class="category">
                                        <label for="radio_1">
                                            <span class="dot one"></span>
                                            <span>Male</span>
                                        </label>
                                        <label for="radio_2">
                                            <span class="dot two"></span>
                                            <span>Female</span>
                                        </label>
                                        <label for="radio_3">
                                            <span class="dot three"></span>
                                            <span>Prefer not to say</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="reg_btn">
                                    <input type="submit" value="Register" />
                                </div>
                            </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}