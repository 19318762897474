import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';



export default function Breadcrumb({title}) {

const HandleBack =(event) => {
  window.history.go(-1)
}
  return (
    <div role="presentation" >
        <div className='dashboard'>
        <div class="row">
          <div class="col-4 col-sm-6 col-md-4 p-r-0 title-margin-right">
            <div class="page-header">
              <div class="page-title2">
               <Link to="#" className='btn-back' onClick={HandleBack}>Back</Link>
              </div>
            </div>
          </div>
          <div class="col-8 col-sm-6 col-md-8 p-l-0 title-margin-left">
            <div class="page-header">
              <div class="page-title">
                <ol class="breadcrumb text-right">
                  <li><a href="#">Home</a></li>
                  <li class="active">{title}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
