import { Container, Row, Col } from 'react-bootstrap'
import './index.scss'
import React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Range } from "react-range";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useMediaQuery } from 'react-responsive'
import Breadcrumb from '../../components/breadcrumb';
const PRODUCTLIST = [{
    categories: "Car Washing",
    subcategories: "Basic Wash",
    price: "120",
    discount: "100",
    disc: "Includes body, rims, and headlights",
    pickdrop: true,
    location: "Noida UP",
    img: [
        "https://i.imgur.com/vuTSHe3.png",
        "https://i.ibb.co/RjvTJY5/7.jpg"
    ]
},
{
    categories: "Delex Washing",
    subcategories: "Basic Wash",
    price: "220",
    discount: "200",
    disc: "Includes body, rims, and headlights",
    pickdrop: true,
    location: "Sector 60,Noida,UP",
    img: [
        "https://i.imgur.com/vuTSHe3.png",
        "https://i.ibb.co/RjvTJY5/7.jpg"
    ]

}]
export default function Shopping() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });

    const [filter, setfilter] = React.useState(isTabletOrMobile ? false : true);
    const showFilter = () => {
        setfilter(!filter)
    }
    return (
        <>
        
            <div className='car-washing-shopping'>
            <Breadcrumb title="Shoping "/>
                <Row>
                    <Col md={3}>
                        <div className='filter' onClick={showFilter}>
                            <Link to="#" ><i class="fa fa-filter me-1"></i>Filter</Link>
                        </div>
                        {filter &&
                            <div id="sidebar" className={filter ? "filtersidebar" : ""}>
                                <div class="product-sidebar">
                                    <div class="product-widget mb-20" >
                                        <div class="check-box-item">
                                            <h6 class="product-widget-title mb-20">Make</h6>
                                            <div class="checkbox-container">
                                                <div class="form-inner">
                                                    <input type="text" placeholder="Search Make" />
                                                </div>
                                                <ul>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Mercedes-Benz </span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Toyota </span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Lambhorghini</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Tesla</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Mazda</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Tata</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Hyundai</span>

                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-widget mb-20" >
                                        <div class="check-box-item">
                                            <h6 class="product-widget-title mb-20">Location</h6>
                                            <div class="checkbox-container">
                                                <div class="form-inner">
                                                    <input type="text" placeholder="Type Location" />
                                                </div>
                                                <ul>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Sydne City</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">New Delhi</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Panama City</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Melbourn City</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">New York</span>

                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-widget mb-20" >
                                        <div class="check-box-item">
                                            <h6 class="product-widget-title mb-20">Body Type</h6>
                                            <hr />
                                            <div class="checkbox-container">
                                                <ul>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Wagon</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Hatchback</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Sedan</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Cab Chassis</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Utility </span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Tata</span>

                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />

                                                            <span class="text">Hyundai</span>

                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </Col>
                    <Col md={9}>
                        {/* <div className="mb-0">
                            <div className="">
                                <div className="item2-gl-nav ">
                                    <h6 className="mb-0 text-left">Showing 1 to 10 of 30 entries</h6>

                                </div>
                            </div>
                        </div> */}
                        <Row>
                            {PRODUCTLIST.map((item, i) => {
                                console.log(item)
                                return (
                                    <Col md={6}>
                                        <div className="product">
                                            <div className="card overflow-hidden">
                                                <div className="d-md-flex">
                                                    <div className="item-card9-img">
                                                        <Swiper
                                                            spaceBetween={10}
                                                            slidesPerView={1}
                                                            loop={true}
                                                            autoplay={{
                                                                delay: 2000,
                                                                disableOnInteraction: true,
                                                            }}
                                                            navigation={true}
                                                            modules={[Navigation, Autoplay, Pagination]}
                                                            breakpoints={{
                                                                320: {
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 10,
                                                                },
                                                                640: {
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 10,
                                                                },
                                                                768: {
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 10,
                                                                },
                                                                1024: {
                                                                    slidesPerView: 1,
                                                                    spaceBetween: 10,
                                                                },
                                                            }}

                                                            className="mySwiper">
                                                            {item.img.map((val, i) => {
                                                                return <SwiperSlide>
                                                                    <div className="washingwale-listing">
                                                                        <div className="listing-img">
                                                                            <a href="listing-details.html">
                                                                                <img src={val} className="img-fluid" alt="Toyota" />
                                                                            </a>

                                                                        </div>

                                                                    </div>
                                                                </SwiperSlide>
                                                            })

                                                            }

                                                        </Swiper>
                                                    </div>
                                                    <div className="card border-0 mb-0 list-data">
                                                        <div className="card-body mb-0 pt-0 pl-0">
                                                            <div className="item-card9">
                                                                <h5 className="font-weight-semibold mt-1">{item.categories} || <span>{item.subcategories}</span></h5>
                                                                <div className="item-card9-desc mb-0">
                                                                    <p className=" d-inline-block">
                                                                        <span className=""><i className="fa fa-map-marker text-muted me-0"></i> {item.location}</span>
                                                                    </p>
                                                                 
                                                                </div>
                                                                <p className="mb-0 leading-tight">{item.disc} </p>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer ">
                                                            <div className="item-card9-footer d-sm-flex">
                                                                <div className="item-card9-cost"><p className="text-dark font-weight-bold mb-0 mt-0">₹{item.discount} <span>₹{item.price}</span></p></div>
                                                                <div className="ms-auto">
                                                                    <a href='/washing-wale-details' class="btn btn-success" >Book Now</a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}

                        </Row>
                    </Col>

                </Row>

            </div>
        </>
    )
}