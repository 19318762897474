import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RegisterSeller from "./RegisterSeller"
 export default function CenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='RegisterSeller-popup'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Ragistarion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <RegisterSeller/>
      </Modal.Body>
   
    </Modal>
  );
}