import { Container, Row, Form, Col } from "react-bootstrap"
import { Rating } from 'react-simple-star-rating'
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import './index.scss';
import { Link } from "react-router-dom";
export const Listing = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
    const [rating, setRating] = useState(5)

    // Catch Rating value
    // const handleRating = (rate) => {
    //   setRating(rate)
    //   console.log(rate)
    //   // other logic
    // }

    return (

        <section className="carlisting-washingwale">

            
                {/* <div className="noida-carwashing-title">
                    <h2 className="washingwale-heading">Your nearest washing wale</h2>
                    <Link>View All</Link>
                </div> */}
                <Swiper
                    spaceBetween={20}
                    slidesPerView={5}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 20,
                        },
                    }}

                    className="mySwiper">
      
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="washingwale-listing">
                            <div className="listing-img">
                                <a href="/washing-wale-details">
                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                </a>

                            </div>
                            <div className="listing-content">
                                <div className="listing-features">
                                    <div className="listing-title">
                                        <p >3D car Washing</p>
                                      
                                    </div>
                                </div>
                                <span style={{fontSize:"12px"}}><i className="fa fa-map-marker me-2"></i>Noida, sector 70</span>
                                <hr style={{ margin: "5px 0" }} />
                                <div className="listing-button">
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                    <span style={{fontSize:"12px"}}><Rating
                                            className="washingwala-car-rating"
                                            initialValue={rating}
                                            readonly={true}
                                        /><en>({rating})</en></span>
                                        <br /></Link>
                                    <Link to='/washing-wale-details' className="btn btn-order">
                                        View details</Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>


                </Swiper>
         
            {/* Top rated washing wale div start */}

            {/* Top rated washing wale div End */}
        </section>

    )
}