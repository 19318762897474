import React, { useState } from "react";

const UpiPaymentPage = ({ setPaymentShow }) => {
  const [upiId, setUpiId] = useState("");
  const [amount, setAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");

  const handlePayment = () => {
    if (!validateUpiId(upiId)) {
      setMessage("Invalid UPI ID. Please enter a valid ID.");
      return;
    }

    if (amount <= 0) {
      setMessage("Enter a valid amount.");
      return;
    }

    setMessage("");
    setIsProcessing(true);

    // Simulate a payment API call
    setTimeout(() => {
      setIsProcessing(false);
      setMessage("Payment successful!");
    }, 3000);
  };

  const validateUpiId = (id) => {
    // Basic validation for UPI ID (e.g., user@bank)
    const regex = /^[a-zA-Z0-9.\-_]{2,}@[a-zA-Z]{2,}$/;
    return regex.test(id);
  };
  const BackButton = () => {
    window.history.go(-1)
  };
  return (
    <div style={styles.container}>
     <a href="#" style={styles.back} onClick={BackButton}> Back</a> <h2>UPI Payment</h2>
      <div style={styles.formGroup}>
        <label htmlFor="upiId">UPI ID:</label>
        <input
          type="text"
          id="upiId"
          value={upiId}
          onChange={(e) => setUpiId(e.target.value)}
          placeholder="Enter your UPI ID"
          style={styles.input}
        />
      </div>
      <div style={styles.formGroup}>
        <label htmlFor="amount">Amount:</label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
          style={styles.input}
        />
      </div>
      <button onClick={handlePayment} style={styles.button} disabled={isProcessing}>
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

const styles = {
  container: {
    padding: "2rem 20px 3rem",
    border: "1px solid #ccc",
    borderRadius: "10px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    backgroundColor:"#fff"
  },
  back:{
    position:"absolute",
    left:"20px",
    top:"20px",
    
  },
  formGroup: {
    marginBottom: "15px",
    textAlign: "left",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginTop: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
  message: {
    marginTop: "20px",
    color: "#d9534f",
  },
};

export default UpiPaymentPage;
