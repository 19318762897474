import { Container, Row, Col, Modal } from "react-bootstrap";
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Navigation, Thumbs, Autoplay, FreeMode } from 'swiper/modules';
import './index.scss';
import { Rating } from "react-simple-star-rating";
import Booking from "../booking";
import Breadcrumb from "../../components/breadcrumb";
import Accordion from 'react-bootstrap/Accordion';

import TopRated from "./TopRated";
import UpiPaymentPage from "../payment";
import { Listing } from "../listing";
const PRODUCT = [{
    CenterName: "Lamborghini Aventador",
    GSTNo: "22AAAAA0000A1Z5",
    Address: "Noide sector 59",
    PickDrop: true,
    SliderImg: ""
}]
export default function Productdetails() {
    // window.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    // });

    const [rating, setRating] = useState(5)
    const [thumbsSwiper, setThumbsSwiper] = useState(0);
    const [modalShow, setModalShow] = React.useState(false);
    const [paymentShow, setPaymentShow] = React.useState(false);
    const handleBookNow = () => {
        setModalShow(true)
        setPaymentShow(false)
    }

    return (
        <>
            <section className="washing-car">
                <Breadcrumb title="Servies Details" />
                <div class="car-details-area pt-100 mb-100">
                    <div class="row">
                        <div class="col-lg-5">
                            <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example" tabindex="0">
                                <div class="single-item mb-50" id="car-img">
                                    <div class="car-img-area">
                                        <div>
                                            <>
                                                <Swiper
                                                    spaceBetween={10}
                                                    navigation={true}
                                                    thumbs={{ swiper: thumbsSwiper }}
                                                    modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                                                    className="mySwiper2"
                                                >
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                                <br />
                                                <Swiper
                                                    onSwiper={setThumbsSwiper}
                                                    spaceBetween={10}
                                                    slidesPerView={7}
                                                    freeMode={true}
                                                    watchSlidesProgress={true}
                                                    modules={[FreeMode, Navigation, Thumbs]}
                                                    className="mySwiper"
                                                >
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="car-details-sidebar">
                                <div class="contact-info mb-10">
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <div class="mb-0">
                                                <div class="banner-content style-2">
                                                    <h5>Lamborghini Aventador</h5>
                                                    <div class="car-washing-wale-rating">

                                                        <Rating
                                                            className="washingwala-car-rating"
                                                            initialValue={rating}
                                                            readonly={true}
                                                        />(40)
                                                    </div>
                                                    <div class="location-and-notification">
                                                        <ul>
                                                            <li><i class="bi bi-geo-alt"></i> Sydne City, Australia</li>
                                                            <li class="alart">
                                                                Available : Pick & Drop

                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                                <div class="single-item mb-50" id="kye-features">
                                                    <div class="car-wash-wale-addtion-features ">
                                                        <div className="row">
                                                            <div class="title mb-10">
                                                                <p><b>Washing Service</b></p>
                                                            </div>
                                                            <ul className="Service-features">
                                                                <li>More</li>
                                                                <li>Service</li>
                                                                <li>Price</li>
                                                                <li>Select</li>
                                                            </ul>
                                                            <Accordion defaultActiveKey="0" alwaysOpen>
                                                                <Accordion.Item eventKey="0">
                                                                    <div className="car-washing-view">
                                                                        <Accordion.Header>
                                                                            <div class="checkboxes__item">
                                                                                <label class="checkbox style-g">
                                                                                    <input type="checkbox" />
                                                                                    <div class="checkbox__checkmark"></div>
                                                                                    <div class="checkbox__body">Car Wash & Coating</div>
                                                                                    <div class="checkbox__body"> 200</div>
                                                                                </label>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
                                                                        </Accordion.Body>
                                                                    </div>
                                                                </Accordion.Item>
                                                            </Accordion>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="inquiry-form Summary mt-60">
                                                <div class="title">
                                                    <h4>Order Summary</h4>
                                                </div>
                                                <div class="cart-menu">
                                                    <div class="cart-footer">
                                                        <div class="pricing-area">
                                                            <ul>
                                                                <li><span>MRP</span><span>468</span></li>
                                                            </ul>
                                                            <ul>
                                                                <li><span>Sub Total</span><span>468</span></li>
                                                                <li><span>Driver Fee (20%)</span><span>56</span></li>
                                                            </ul>
                                                            <ul class="total">
                                                                <li><span>Total</span><span>425</span></li>
                                                            </ul>
                                                        </div>

                                                        <div class="form-inner">
                                                            <button class="primary-btn3" type="submit" onClick={handleBookNow}>Book Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-8 col-lg-7 ">
                            <hr />
                            <div class="single-item mb-10" id="qus-ans">
                                <div class="user-qustion-area">
                                    <div class="section-title-and-filter ">
                                        <div class="title">
                                            <h5>Read what others have said about us</h5>
                                        </div>

                                    </div>
                                    <br />
                                    <div class="ClientReview_Card">
                                        <div class="ClientReview_Top">

                                            <div class="ClientReview_Stars">
                                                ★★★★★
                                            </div>
                                        </div>
                                        <div class="ClientReview_Body">
                                            "Krista and Clint are AMAZING. The have been handling an issue for us, and it is so nice not to have to worry about anything. Very professional and easy to reach with any questions or concerns. I highly recomend."
                                        </div>
                                        <div class="ClientReview_Name">Chonya Alvarez
                                        </div>

                                    </div><br />

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-5">
                            <div class="recent-car-area">
                                <div class="title mb-30">
                                    <h5>Top Rated Washing Wala</h5>
                                </div>

                                <TopRated />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="car-details-area pt-100 mb-100" style={{background:"#fff",padding:"10px"}}>
                    <div class="row">
                        <div class="title">
                            <h5>Similar washing wale</h5>
                        </div>
                        <Listing />
                    </div>
                 
                </div>
               
            </section>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='booking-model'
            >

                <Modal.Body>
                    {paymentShow ? (<UpiPaymentPage setPaymentShow={setPaymentShow} />) : <Booking setPaymentShow={setPaymentShow} />}
                </Modal.Body>

            </Modal>
        </>
    )
}