import { Col, Nav, Row, Tab } from "react-bootstrap"
import "./index.scss"
import Table from "../../components/table"
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Autoplay, FreeMode, Pagination } from 'swiper/modules';
import Breadcrumb from "../../components/breadcrumb";
const PRODUCTLIST = [{
    categories: "Car Washing",
    subcategories: "Basic Wash",
    price: "120",
    discount: "100",
    disc: "Includes body, rims, and headlights",
    pickdrop: true,
    location: "Noida UP",
    img: [
        "https://i.imgur.com/vuTSHe3.png",
        "https://i.ibb.co/RjvTJY5/7.jpg"
    ]},
    {
        categories: "Delex Washing",
        subcategories: "Basic Wash",
        price: "220",
        discount: "200",
        disc: "Includes body, rims, and headlights",
        pickdrop: true,
        location: "Sector 60,Noida,UP",
        img: [
            "https://i.imgur.com/vuTSHe3.png",
            "https://i.ibb.co/RjvTJY5/7.jpg"
        ]
    
    }]
export default function Profile() {
    return (

        <div className="Washing-wale-profile">
            <br /><br />
            <Breadcrumb title="Profile "/>
            <section>
                <div class="main-body">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="Profile">
                        <div class="row">

                            <div class="col-lg-3">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex flex-column align-items-center text-center">
                                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtbEsykx-0fhTred6UwHDYtMFd2UgTJCG4gaklT1dx4suRO4_n5LJr4Gg28kquSX5fpNo&usqp=CAU" alt="Admin"
                                                class="rounded-circle p-1 bg-warning" width="70" />
                                            <div class="mt-3">
                                                <h6>Washing Wale</h6>
                                                <p class="text-secondary mb-1">+91 000000000</p>
                                                <p class="text-muted font-size-sm">Delhi, NCR</p>
                                            </div>
                                        </div>
                                        <div class="list-group list-group-flush text-center">
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Profile">Profile</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Booking">Latest Booking</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="History">History</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <Tab.Content>
                                    <Tab.Pane eventKey="Profile">
                                        <div class="Profile">
                                            <div class="card mt-1">

                                                <div class="card-body">
                                                    <div class="profile-info">
                                                        <h5>Profile Information</h5>
                                                        <hr />
                                                        <p><strong>Name:</strong> Washing Wale</p>
                                                        <p><strong>Email Address:</strong> helpwashingwale@gmail.com</p>
                                                        <p><strong>Contact:</strong> +91 0000000</p>
                                                        <p><strong>Date of Birth:</strong> 02-03-1999</p>
                                                        <p><strong>Gender:</strong> Male</p>
                                                        <p><strong>City:</strong> Delhi, NCR</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Booking">
                                        <div id="orderDetails" class="order_card">

                                            <div class="card mt-4">
                                                <div class="card-body p-0 table-responsive">
                                                    <h5 class="p-1 mb-0">Booking Details</h5>
                                                    <table class="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Description</th>

                                                                <th scope="col" >Amount</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                                <td>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolorem,
                                                                    facilis.</td>

                                                                <td><strong>₹200</strong></td>
                                                                <td><span class="badge badge-warning">PENDING</span></td>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="2">
                                                                    <span>Status:</span>
                                                                    <span class="badge badge-success">PAID</span>
                                                                </th>
                                                                <td >
                                                                    <span class="text-muted">Price</span>
                                                                    <strong>₹200</strong>
                                                                </td>


                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card mt-4">
                                                <div class="card-body">
                                                    <h4>Timeline</h4>
                                                    <ul class="timeline">
                                                        <li class="active">
                                                            <h6>PICKED</h6>
                                                            <p class="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque Lorem ipsum dolor</p>
                                                            <o class="text-muted">21 March, 2014</o>
                                                        </li>
                                                        <li>
                                                            <h6>PICKED</h6>
                                                            <p class="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque</p>
                                                            <o class="text-muted">21 March, 2014</o>
                                                        </li>
                                                        <li>
                                                            <h6>PICKED</h6>
                                                            <p class="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque</p>
                                                            <o class="text-muted">21 March, 2014</o>
                                                        </li>
                                                        <li>
                                                            <h6>PICKED</h6>
                                                            <p class="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque</p>
                                                            <o class="text-muted">21 March, 2014</o>
                                                        </li>
                                                        <li>
                                                            <h6>PICKED</h6>
                                                            <p class="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque</p>
                                                            <o class="text-muted">21 March, 2014</o>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="addAddressModal" class="modal">
                                                <div class="modal-content">
                                                    <span class="close" onclick="closeAddAddressModal()">&times;</span>
                                                    <h2>Add Address</h2>
                                                    <form id="addAddressForm" onsubmit="saveAddress(event)">

                                                        <div class="col-12 d-flex main_flex_div">
                                                            <div class="col-4 d-flex flex-column inner_flex_div">
                                                                <label for="name">Name:</label>
                                                                <input type="text" id="name" required /><br />
                                                            </div>
                                                            <div class="col-4 d-flex flex-column inner_flex_div">
                                                                <label for="mobile">Mobile No.:</label>
                                                                <input type="tel" id="mobile" required pattern="[0-9]{10}" />
                                                            </div>
                                                            <div class="col-4 d-flex flex-column inner_flex_div">
                                                                <label for="pincode">Pin code:</label>
                                                                <input type="text" id="pincode" required /><br />

                                                            </div>
                                                        </div>

                                                    </form>
                                                    <div class="col-12 d-flex main_flex_div">

                                                        <div class="col-4 d-flex flex-column inner_flex_div">
                                                            <label for="locality">Locality:</label>
                                                            <input type="text" id="locality" required /><br />
                                                        </div>

                                                        <div class="col-4 d-flex flex-column inner_flex_div">
                                                            <label for="city">City/District/Town:</label>
                                                            <input type="text" id="city" required /><br />

                                                        </div>

                                                        <div class="col-4 d-flex flex-column inner_flex_div">
                                                            <label for="state">State:</label>
                                                            <select id="state" required>
                                                                <option value="">Select a state</option>
                                                                <option value="State 1">State 1</option>
                                                                <option value="State 2">State 2</option>
                                                                <option value="State 3">State 3</option>
                                                            </select><br />
                                                        </div>

                                                    </div>

                                                    <div class="col-12 d-flex main_flex_div">
                                                        <div class="col-12 d-flex flex-column inner_flex_div">
                                                            <label for="address">Address:</label>
                                                            <textarea id="address" required></textarea><br />
                                                        </div>

                                                    </div>


                                                    <div class="col-12 d-flex main_flex_div">
                                                        <div class="col-6 d-flex flex-column inner_flex_div">
                                                            <label for="landmark">Landmark (Optional):</label>
                                                            <input type="text" id="landmark" /><br />

                                                        </div>
                                                        <div class="col-6 d-flex flex-column inner_flex_div">
                                                            <label for="alternatePhone">Alternate Phone (Optional):</label>
                                                            <input type="tel" id="alternatePhone" pattern="[0-9]{10}" /><br />
                                                        </div>


                                                    </div>

                                                    <div class="col-12 d-flex button_div">
                                                        <button type="submit">Save</button>
                                                        <button type="button" onclick="closeAddAddressModal()">Cancel</button>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="History">
                                        <div className="car-washing-delhi-table">
                                            <Row>
                                                    {PRODUCTLIST.map((item, i) => {
                                                        console.log(item)
                                                        return (
                                                            <Col md={6}>
                                                                <div className="product">
                                                                    <div className="card overflow-hidden">
                                                                        <div className="d-md-flex">
                                                                            <div className="item-card9-img">
                                                                            <div className="status"><p>Complete</p>
                                                                            </div>
                                                                                <Swiper
                                                                                    spaceBetween={10}
                                                                                    slidesPerView={1}
                                                                                    loop={true}
                                                                                    autoplay={{
                                                                                        delay: 2000,
                                                                                        disableOnInteraction: true,
                                                                                    }}
                                                                                    navigation={true}
                                                                                    modules={[Navigation, Autoplay, Pagination]}
                                                                                    breakpoints={{
                                                                                        320: {
                                                                                            slidesPerView: 1,
                                                                                            spaceBetween: 10,
                                                                                        },
                                                                                        640: {
                                                                                            slidesPerView: 1,
                                                                                            spaceBetween: 10,
                                                                                        },
                                                                                        768: {
                                                                                            slidesPerView: 1,
                                                                                            spaceBetween: 10,
                                                                                        },
                                                                                        1024: {
                                                                                            slidesPerView: 1,
                                                                                            spaceBetween: 10,
                                                                                        },
                                                                                    }}

                                                                                    className="mySwiper">
                                                                                    {item.img.map((val, i) => {
                                                                                        return <SwiperSlide>
                                                                                            <div className="washingwale-listing">
                                                                                           
                                                                                                <div className="listing-img">
                                                                                                    <a href="listing-details.html">
                                                                                                        <img src={val} className="img-fluid" alt="Toyota" />
                                                                                                    </a>

                                                                                                </div>

                                                                                            </div>
                                                                                        </SwiperSlide>
                                                                                    })

                                                                                    }

                                                                                </Swiper>
                                                                            </div>
                                                                            <div className="card border-0 mb-0 list-data">
                                                                                <div className="card-body mb-0 pt-0 pl-0">
                                                                                  
                                                                                    <div className="item-card9">
                                                                                        <h5 className="font-weight-semibold mt-1">{item.categories} || <span>{item.subcategories}</span></h5>
                                                                                        <div className="item-card9-desc mb-0">
                                                                                            <p className=" d-inline-block">
                                                                                                <span className=""><i className="fa fa-map-marker text-muted me-0"></i> {item.location}</span>
                                                                                            </p>
                                                                                            {item.pickdrop && <p className="me-1 d-inline-block">
                                                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                                                            </p>
                                                                                            }
                                                                                        </div>
                                                                                        <p className="mb-0 leading-tight">{item.disc} </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-footer ">
                                                                                    <div className="item-card9-footer d-sm-flex">
                                                                                        <div className="item-card9-cost"><p className="text-dark font-weight-bold mb-0 mt-0">₹{item.discount} <span>₹{item.price}</span></p></div>
                                                                                        <div className="ms-auto">
                                                                                            <button type="button" class="btn btn">Book Again</button>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                               

                                            </Row>

                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>

            </section>
        </div>



    )
}
