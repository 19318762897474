import React from 'react';
import './index.css';
import './app.scss';
import './responsive.scss';
import App from './App';
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    
    <App/>
  </StrictMode>,
  rootElement
);
