export default function Bannerpage (){
    return(
        <div class="banner-block clearfix">
                    <div class="row">
                        <div class="col-sm-12">
                                <img src="/img/inner-page/car-washing-wale-baneer-2.jpg" alt="car-washing-wale_banner" />
                                <div class="banner-contant b-right">
                                	<div class="banner-off">Off</div>
                                	<span class="b-big">30%</span>
                                    <ul>
                                        <li>
                                            <span class="b-name">First Time</span>
                                        </li>
                                        <li>
                                            <span class="b-xs-big"> Booking</span>
                                        </li>
                                    </ul>
                                    <span class="b-btn">Shop Now</span>
                                </div>
                           
                        </div>
                    </div>
          
            </div>
    )
}