import { Container, Row } from "react-bootstrap"
import "./index.scss";
import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
export const Footer = () => {
    const [value, setValue] = React.useState('recents');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className="washing-wale-footer">
                <Container fluid>

                    <div class="footer-top">
                        <div class="row">
                            <div class="col-md-2 col-sx-12 justify-content-lg-start">
                                <div class="footer-widget">
                                    <div class="widget-title">
                                        <h5>About Company</h5>
                                    </div>
                                    <div class="menu-container">
                                        <ul>
                                            <li><a href="/about">About Us
                                            </a></li>
                                            {/* <li><a href="return-enchange.html">Return &amp; Exchange
                                            </a></li> */}
                                            <li><a href="/blog-page">blog
                                            </a></li>
                                            {/* <li><a href="faq.html">FAQ’s
                                            </a></li> */}
                                            <li><a href="/contact-us">Contact Us
                                            </a></li>
                                            <li><a href="/washing-wale-seller">Services Listing
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sx-12 justify-content-sm-center">
                                <div class="footer-widget">
                                    <div class="widget-title">
                                        <h5>Services</h5>
                                    </div>
                                    <div class="menu-container">
                                        <ul>
                                            <li><a href="/washing-wale-shopping">Car washing
                                            </a></li>
                                            <li><a href="/washing-wale-shopping">Bike washing
                                            </a></li>
                                            <li><a href="/washing-wale-shopping">Bus
                                            </a></li>
                                            <li><a href="/washing-wale-shopping">Truck
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-sx-12 justify-content-lg-center justify-content-sm-end">
                                <div class="footer-widget">
                                    <div class="widget-title">
                                        <h5>Center Location</h5>
                                    </div>
                                    <div class="menu-container">
                                        <ul>
                                            <li><a href="/washing-wale-shopping">Delhi
                                            </a></li>
                                            <li><a href="/washing-wale-shopping">Noida
                                            </a></li>
                                            <li><a href="/washing-wale-shopping">Gourgram
                                            </a></li>
                                            <li><a href="/washing-wale-shopping">Mumbai
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sx-12 justify-content-xl-center justify-content-lg-end justify-content-sm-center">
                                <div class="footer-widget">
                                    <div class="widget-title">
                                        <h5>Consumer policy</h5>
                                    </div>
                                    <div class="menu-container">
                                        <ul>
                                            <li><a href="/cancellation-policy">Cancellation & No refund
                                            </a></li>
                                            <li><a href="/terms-and-conditions">Terms and Conditions
                                            </a></li>
                                            <li><a href="/privacy-policy">Privacy Policy
                                            </a></li>
                                            <li><a href="/booking-policy">Booking Policy
                                            </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sx-12 justify-content-xl-center justify-content-lg-end justify-content-sm-center">
                                <div class="footer-widget">
                                    <div class="widget-title">
                                        <h5>Address</h5>
                                    </div>
                                    <div class="menu-container" style={{color:"#b3b3b3"}}>
                                       <p>Sangam Enclave Road, Basai, Sector 70, Noida, Uttar Pradesh</p>
                                       <p>Email : helpwashingwale@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="footer-btm">
                        <div class="copyright-area">
                            <p>Copyright 2023 <a href="#">Washing Wale</a></p>
                        </div>
                        <div class="social-area">
                            <ul>
                                <li><a href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://twitter.com/"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/"><i class="fa fa-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </Container>

            </div>


        </>
    )
}