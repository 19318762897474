import React from 'react';
import "./index.scss"
const BookingPolicy = () => {
  return (
    <div className="privacy">
        <br/><br/><br/>
      <div className="container">
        <h1>Booking Policy</h1>

        <section className="policy-section">
          <h2>1. Booking Guidelines</h2>
          <p>
            - Bookings can be made online through our platform or mobile app.  
            - Ensure all required details, including vehicle information and contact details, are accurate.  
            - Bookings are subject to availability and confirmation by the car washing center.  
          </p>
        </section>

        <section className="policy-section">
          <h2>2. Booking Confirmation</h2>
          <p>
            - Once your booking is confirmed, you will receive a confirmation email or notification with the booking details.  
            - If a booking cannot be accommodated, you will be notified promptly and offered alternative time slots or services.  
          </p>
        </section>

        <section className="policy-section">
          <h2>3. Rescheduling</h2>
          <p>
            - Rescheduling requests must be made at least <strong>[1 hours]</strong> before the scheduled service time.  
            - Rescheduling is subject to availability and may involve additional charges depending on the provider’s policy.  
          </p>
        </section>

        <section className="policy-section">
          <h2>4. Cancellation</h2>
          <p>
            - Cancellation requests must be made in accordance with our <a href="/cancellation-policy">Cancellation & No Refund Policy</a>.  
            - Late cancellations or no-shows will not be eligible for refunds.  
          </p>
        </section>

        <section className="policy-section">
          <h2>5. Payment</h2>
          <p>
            - Payments must be made at the time of booking or as per the payment methods provided on the platform.  
            - Failure to complete payment may result in the cancellation of the booking.  
          </p>
        </section>

        <section className="policy-section">
          <h2>6. Customer Responsibilities</h2>
          <p>
            - Provide accurate information during the booking process.  
            - Ensure vehicle accessibility and availability at the time of the scheduled service.  
            - Notify the provider in advance if there are any special requirements or conditions.  
          </p>
        </section>

        <section className="policy-section">
          <h2>7. Service Delays or Changes</h2>
          <p>
            - In the event of delays or changes to your scheduled booking, the car washing center or Washing Wale will notify you promptly.  
            - If the service cannot be provided as scheduled, alternative arrangements will be offered.  
          </p>
        </section>

        <section className="policy-section">
          <h2>8. Disputes</h2>
          <p>
            - Any disputes or concerns about services must be reported immediately to the service provider or Washing Wale support.  
            - We will work to resolve disputes fairly and in accordance with our policies.  
          </p>
        </section>

        <section className="policy-section">
          <h2>9. Contact Us</h2>
          <p>
            For any questions or assistance regarding bookings, please contact us:<br />
            - <strong>Email:</strong>  helpwashingwale@gmail.com<br />
            - <strong>Phone:</strong> +91 7217838352<br />
            - <strong>Support Hours:</strong> 24*7
          </p>
        </section>
      </div>

      <style jsx>{`
        .policy-page {
          background-color: #f9f9f9;
          padding: 20px;
        }
        .container {
          max-width: 1000px;
          margin: 0 auto;
          background: #fff;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        h1 {
          text-align: center;
          margin-bottom: 20px;
        }
        .effective-date {
          text-align: center;
          font-style: italic;
          margin-bottom: 20px;
        }
        .policy-section {
          margin-bottom: 30px;
        }
        h2 {
          margin-bottom: 10px;
          color: #333;
        }
        p, ul {
          line-height: 1.6;
          color: #555;
        }
        ul {
          list-style-type: disc;
          margin-left: 20px;
        }
        a {
          color: #007bff;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
};

export default BookingPolicy;
