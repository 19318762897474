

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Col, Row } from 'react-bootstrap';
const PRODUCTLIST = [{
    categories: "Car Washing",
    subcategories: "Basic Wash",
    price: "120",
    discount: "100",
    disc: "Includes body, rims, and headlights",
    pickdrop: true,
    location: "Noida UP",
    img: [
        "https://i.imgur.com/vuTSHe3.png",
        "https://i.ibb.co/RjvTJY5/7.jpg"
    ]
},
{
    categories: "Delex Washing",
    subcategories: "Basic Wash",
    price: "220",
    discount: "200",
    disc: "Includes body, rims, and headlights",
    pickdrop: true,
    location: "Sector 60,Noida,UP",
    img: [
        "https://i.imgur.com/vuTSHe3.png",
        "https://i.ibb.co/RjvTJY5/7.jpg"
    ]

}]
export default function TopRated({
    handleShow
}) {
    return (

        <Row>
            {PRODUCTLIST.map((item, i) => {
                console.log(item)
                return (
                    <Col md={12}>
                        <div className="product">
                            <div className="card overflow-hidden">
                                <div className="d-md-flex">
                                    <div className="item-card9-img">
                                        <Swiper
                                            spaceBetween={10}
                                            slidesPerView={1}
                                            loop={true}
                                            autoplay={{
                                                delay: 2000,
                                                disableOnInteraction: true,
                                            }}
                                            navigation={true}
                                            modules={[Navigation, Autoplay, Pagination]}
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                640: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                768: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                1024: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                            }}

                                            className="mySwiper">
                                            {item.img.map((val, i) => {
                                                return <SwiperSlide>
                                                    <div className="washingwale-listing">
                                                        <div className="listing-img">
                                                            <a href="listing-details.html">
                                                                <img src={val} className="img-fluid" alt="Toyota" />
                                                            </a>

                                                        </div>

                                                    </div>
                                                </SwiperSlide>
                                            })

                                            }

                                        </Swiper>
                                    </div>
                                    <div className="card border-0 mb-0 list-data">
                                        <div className="card-body mb-0 pt-0 pl-0">
                                            <div className="item-card9">
                                                <h5 className="font-weight-semibold mt-1">{item.categories} || <span>{item.subcategories}</span></h5>
                                                <div className="item-card9-desc mb-0">
                                                    <p className=" d-inline-block">
                                                        <span className=""><i className="fa fa-map-marker text-muted me-0"></i> {item.location}</span>
                                                    </p>
                                                    {item.pickdrop && <p className="me-1 d-inline-block">
                                                        <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                    </p>
                                                    }
                                                </div>
                                                <p className="mb-0 leading-tight">{item.disc} </p>
                                            </div>
                                        </div>
                                        <div className="card-footer ">
                                            <div className="item-card9-footer d-sm-flex">
                                                <div className="item-card9-cost"><p className="text-dark font-weight-bold mb-0 mt-0">₹{item.discount} <span>₹{item.price}</span></p></div>
                                                <div className="ms-auto">
                                                    <button type="button" class="btn btn-success">Book Now</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Col>
                )
            })}

        </Row>
    )
}