import React from 'react';
import "./index.scss"
const CancellationPolicy = () => {
  return (
    <div className="privacy">
        <br/> <br/>
      <div className="container">
        <h1>Cancellation & No Refund Policy</h1>

        <section className="policy-section">
          <h2>1. Cancellation Policy</h2>
          <p>
            <strong>Customer-Initiated Cancellations:</strong><br />
            - Cancellations must be requested at least <strong>1 hours</strong> before the scheduled service time.<br />
            - To cancel your booking, log in to your account, navigate to the “My Bookings” section, and select “Cancel.” Alternatively, contact our support team at <strong> helpwashingwale@gmail.com</strong>.
          </p>
          <p>
            <strong>Late Cancellations:</strong><br />
            - Cancellations made less than <strong>Booking Approved</strong> before the scheduled service will be considered late. In such cases, <strong>no refund or rescheduling</strong> will be offered.
          </p>
          <p>
            <strong>No-Show Policy:</strong><br />
            - If you fail to be present or provide access to your vehicle at the scheduled service time, the booking will be marked as a no-show, and no refund will be provided.
          </p>
          <p>
            <strong>Rescheduling Policy:</strong><br />
            - Rescheduling requests must be made at least <strong>24hrs</strong> before the scheduled service time. Rescheduling is subject to availability.
          </p>
        </section>

        <section className="policy-section">
          <h2>2. No Refund Policy</h2>
          <p>
            <strong>Non-Refundable Payments:</strong><br />
            - Payments made for services are non-refundable, except in cases where a service cannot be fulfilled due to reasons attributable to Washing Wale.
          </p>
          <p>
            <strong>Service Issues:</strong><br />
            - If you encounter any issues with the service provided, please contact us immediately at <strong> helpwashingwale@gmail.com</strong>. We will investigate the matter and, if deemed appropriate, provide a resolution (e.g., a complimentary service or discount).
          </p>
        </section>

        <section className="policy-section">
          <h2>3. Company-Initiated Cancellations</h2>
          <p>
            - If Washing Wale is unable to fulfill a scheduled service due to unforeseen circumstances (e.g., equipment failure, extreme weather), we will notify you as soon as possible.<br />
            - In such cases, you will have the option to reschedule the service at no additional cost or receive a full refund.
          </p>
        </section>

        <section className="policy-section">
          <h2>4. Exceptions</h2>
          <p>
            <strong>Force Majeure:</strong><br />
            - In cases of natural disasters, strikes, pandemics, or other events beyond our control, Washing Wale reserves the right to cancel or reschedule services without prior notice. Refunds will not be issued for cancellations caused by such events.
          </p>
          <p>
            <strong>Special Promotions:</strong><br />
            - Services purchased during special promotions or with discount codes may have additional restrictions. Please refer to the terms of the promotion for details.
          </p>
        </section>

        <section className="policy-section">
          <h2>5. Contact Us</h2>
          <p>
            If you have any questions or concerns regarding our Cancellation & No Refund Policy, please reach out to us:<br />
            - <strong>Email:</strong>  helpwashingwale@gmail.com<br />
            - <strong>Phone:</strong> +91 7217838352<br />
            - <strong>Support Hours:</strong> 24*7
          </p>
        </section>

        <footer className="acknowledgment">
          <p>
            By booking a service on <strong>Washing Wale</strong>, you acknowledge that you have read, understood, and agreed to our Cancellation & No Refund Policy.
          </p>
        </footer>
      </div>

      <style jsx>{`
        .policy-page {
          background-color: #f9f9f9;
          padding: 20px;
        }
        .container {
          max-width: 1000px;
          margin: 0 auto;
          background: #fff;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
        h1 {
          text-align: center;
          margin-bottom: 20px;
        }
        .effective-date {
          text-align: center;
          font-style: italic;
          margin-bottom: 20px;
        }
        .policy-section {
          margin-bottom: 30px;
        }
        h2 {
          margin-bottom: 10px;
          color: #333;
        }
        p {
          line-height: 1.6;
          color: #555;
        }
        footer.acknowledgment {
          text-align: center;
          margin-top: 30px;
          font-size: 0.9rem;
          color: #777;
        }
      `}</style>
    </div>
  );
};

export default CancellationPolicy;

