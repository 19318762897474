
import React from 'react';
import "./index.scss"
const steps = [
    {
      id: 1,
      title: "Book Your Car Washing Service 📅",
      description:
        "Choose your preferred car washing package, schedule an appointment online or via call, and select pick & drop or doorstep service.",
},
{
  id: 2,
  title: "Professional Car Washing in Action 🚿",
  description:
    "Our experts start the car washing process with exterior wash, interior cleaning, and tire shine, using eco-friendly products and advanced techniques.",
},
{
  id: 3,
  title: "Process Done – Enjoy Your Clean Car! ✨",
  description:
    "Your car is spotless, fresh, and shining. We return your car if you selected pick & drop service. Drive away in a perfectly cleaned vehicle!",
},
];

function About() {
    return (
        <div>
            <br /> <br />
            <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" style={{ background: `url('/hero-bg8.jpg')no-repeat center center / cover` }}>

                <div class="container">
                    <div class="row">
                        <div class="col-md-7 col-lg-6">
                            <div className='text-back'>
                                <div class="hero-slider-content">
                                    <h2>About Our Car Washing Services</h2>
                                    <p class="lead">Welcome to Premium Car Washing Services, your trusted partner in keeping your car sparkling clean.
                                        Our car washing techniques use the latest technology to ensure a spotless and shiny vehicle.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <br />
            <br />
            <section className="eni pt-100 ct-01 content-section division">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-12 aos-init aos-animate" data-aos="fade">
                            <div class="txt-block right-column" >
                                <h2 class=" text-center">Core Business Areas
                                </h2>

                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="car-washing testing-gray aos-init aos-animate">

                                <p class="text-center"><span> Eco-friendly car washing techniques.</span><br /> Eco-friendly car washing techniques help keep vehicles clean while protecting the environment. Traditional car washing methods use excessive water and harmful chemicals, leading to pollution and waste. By adopting eco-friendly car washing practices, we can reduce water consumption, eliminate toxic runoff, and ensure a sustainable future for vehicle maintenance.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="car-washing testing-gray aos-init aos-animate" data-aos="fade-in">

                                <p class="text-center"><span> Affordable car washing packages for all vehicle types</span><br /> we offer affordable car washing packages designed to suit every vehicle type and budget. Whether you own a compact car, an SUV, or a luxury vehicle, our car washing services ensure a spotless and shining finish at the best prices.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="car-washing testing-gray aos-init aos-animate" data-aos="fade-in" >

                                <p class="text-center"><span>Advanced steam car washing for deep cleaning</span><br />Advanced steam car washing is an innovative and eco-friendly technique that uses high-pressure steam to clean and disinfect vehicles. This car washing method removes dirt, grease, and bacteria without using excessive water or harmful chemicals, making it ideal for deep cleaning.</p>
                            </div>
                        </div>



                        <div class="col-md-6">
                            <div class="car-washing testing-gray aos-init" data-aos="fade-in" >

                                <p class="text-center"><span>Mobile car washing pick and drop at your doorstep.</span><br />Enjoy a hassle-free car washing experience with our pick & drop service! We bring premium car washing to your doorstep, so you don’t have to waste time at a car wash center. Our team will pick up your car, give it a professional car washing treatment, and return it spotless and shining—all from the comfort of your home!</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <br /><br />
            <section class=" mt-5 mt-md-0 mt-lg-0 mt-xl-0">
                <div class="container">
                 
                        <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
                            <h2 className="text-3xl font-bold text-blue-600 text-center mb-6">
                                Our Car Washing Process 🚗💦
                            </h2>
                            <div className="space-y-6">
                                {steps.map((step) => (
                                    <div key={step.id} className="p-4 bg-white rounded-lg shadow">
                                        <h3 className="text-xl font-semibold text-blue-500">
                                            {step.id}. {step.title}
                                        </h3>
                                        <p className="text-gray-700">{step.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                  
                </div>
            </section>

            <br />
            <section class="bg-blue-gr mt-5 mt-md-0 mt-lg-0 mt-xl-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h2 class="text-white">Book Your Car Washing Today<br class="d-none d-md-none d-lg-block d-xl-block" /></h2>
                            <img src="images/img/heading-line-dec-w.png" alt="" class="pb-2" />
                            <p class="para1 text-white">Get the best car washing experience in town! Book an appointment now and enjoy a clean, fresh-smelling car.
                                We guarantee 100% satisfaction with our car washing services.</p>
                        </div>
                    </div>
                </div>
            </section>
            <br />
        </div>
    );
}

export default About;

